import { useEffect, useState } from "react";
import dashboardRefresh from "./DashboardRefresh.gif";
import axios from "axios";
function App() {
  const [state, setState] = useState("idle");
  const [content, setContent] = useState();

  useEffect(() => {
    const postData = {};
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.forEach((value, key) => {
      postData[key] = value;
    });
    setState("loading");
    axios
      .post("https://api.pro.iheart.io/api/web/iheart/article", postData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(({ data }) => {
        if (data) {
          if (data.code === 0) {
            setContent(data.data.contents);
            setState("success");
          } else {
            setState("fail");
          }
        }
      })
      .catch(() => {
        setState("fail");
      });
  }, []);

  return (
    <>
      <div className="App">
        {state === "loading" && (
          <div className="loading">
            <img src={dashboardRefresh} alt="Dashboard Refresh" />
          </div>
        )}
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </>
  );
}

export default App;
